import { Address, PublicClient } from 'viem'
import BN, { BigNumber } from 'bignumber.js'
import { BIG_TWO, BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { ChainId } from '@pancakeswap/sdk'
import { CurrencyParams, getCurrencyKey, getCurrencyListUsdPrice } from '@pancakeswap/utils/getCurrencyPrice'
import { getFarmsPrices, getFarmLpTokenPrice } from './farmPrices'
import { fetchPublicFarmsData } from './fetchPublicFarmData'
import { SerializedFarmConfig } from '../types'
import { getFullDecimalMultiplier } from './getFullDecimalMultiplier'
import { FarmV2SupportedChainId, supportedChainIdV2 } from '../const'

export const SECONDS_IN_YEAR = 31536000 // 365 * 24 * 60 * 60

const evmNativeStableLpMap: Record<
  FarmV2SupportedChainId,
  {
    address: Address
    wNative: string
    stable: string
  }
> = {
  // [ChainId.BSC]: {
  //   address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   wNative: 'WBNB',
  //   stable: 'BUSD',
  // },
  [ChainId.BSC]: {
    address: '0xFcEA8881d702794918f99421B01698875EAD6cCF',
    wNative: 'WBNB',
    stable: 'USDT',
  },
  [ChainId.BSC_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBNB',
    stable: 'BUSD',
  },
  //! CHECK
  [ChainId.OPBNB]: {
    address: '0xA4c83a9b5e360492077e23eC880610E538ce2Ae0',
    wNative: 'WBNB',
    stable: 'BUSD',
  },
}

export const getTokenAmount = (balance: BN, decimals: number) => {
  return balance.div(getFullDecimalMultiplier(decimals))
}

export type FetchFarmsParams = {
  farms: SerializedFarmConfig[]
  provider: ({ chainId }: { chainId: number }) => PublicClient
  chainId: number
}

export async function farmV2FetchFarms({ farms, provider, chainId }: FetchFarmsParams) {
  if (!supportedChainIdV2.includes(chainId)) {
    return []
  }

  const lpDataResults = await fetchPublicFarmsData(farms, chainId, provider)

  const lpData = lpDataResults.map(formatClassicFarmResponse)
  const farmsData = farms.map((farm, index) => {
    try {
      return {
        ...farm,
        ...getClassicFarmsDynamicData({
          ...lpData[index],
          token0Decimals: farm.token.decimals,
          token1Decimals: farm.quoteToken.decimals,
        }),
      }
    } catch (error) {
      console.error(error, farm, index, {
        token0Decimals: farm.token.decimals,
        token1Decimals: farm.quoteToken.decimals,
      })
      throw error
    }
  })

  const decimals = 18
  const farmsDataWithPrices = getFarmsPrices(
    farmsData as any,
    evmNativeStableLpMap[chainId as FarmV2SupportedChainId],
    decimals,
  )

  // return farmsDataWithPrices;

  const tokensWithoutPrice = farmsDataWithPrices.reduce<Map<string, CurrencyParams>>((acc, cur) => {
    if (cur.tokenPriceBusd === '0') {
      acc.set(cur.token.address, cur.token)
    }
    if (cur.quoteTokenPriceBusd === '0') {
      acc.set(cur.quoteToken.address, cur.quoteToken)
    }
    return acc
  }, new Map<string, CurrencyParams>())

  const tokenInfoList = Array.from(tokensWithoutPrice.values())

  if (tokenInfoList.length) {
    const prices = await getCurrencyListUsdPrice(tokenInfoList)

    return farmsDataWithPrices.map((f) => {
      if (f.tokenPriceBusd !== '0' && f.quoteTokenPriceBusd !== '0') {
        return f
      }

      const tokenKey = getCurrencyKey(f.token)
      const quoteTokenKey = getCurrencyKey(f.quoteToken)
      const tokenPrice = new BN(tokenKey ? prices[tokenKey] ?? 0 : 0)
      const quoteTokenPrice = new BN(quoteTokenKey ? prices[quoteTokenKey] ?? 0 : 0)
      const lpTokenPrice = getFarmLpTokenPrice(f, tokenPrice, quoteTokenPrice, decimals)

      const tokenInUsd = new BigNumber(f.tokenAmountTotal).multipliedBy(tokenPrice)
      const quoteInUsd = new BigNumber(f.quoteTokenAmountTotal).multipliedBy(quoteTokenPrice)

      const lpTokenValue = (new BigNumber(tokenInUsd).plus(new BigNumber(quoteInUsd))).div(new BigNumber(f.lpTotalSupply).shiftedBy(-18))
      // @ts-ignore
      const liquidity = lpTokenValue.multipliedBy(new BigNumber(f.lpTokenStakedAmount).shiftedBy(-18)).toString();

      const totalRewardsPerYear = (f.rewardRate ? f.rewardRate : new BigNumber(0)).shiftedBy(-f.quoteToken.decimals).multipliedBy(SECONDS_IN_YEAR);

      // Calculate the APR as a decimal
      const APRDecimal = (totalRewardsPerYear.multipliedBy(quoteTokenPrice)).div(new BigNumber(liquidity));

      // Calculate the APR as a percentage
      const APRPercentage = APRDecimal.multipliedBy(100);

      return {
        ...f,
        liquidity,
        apy: APRPercentage.toString(),
        tokenPriceBusd: tokenPrice.toString(),
        quoteTokenPriceBusd: quoteTokenPrice.toString(),
        lpTokenPrice: lpTokenPrice.toString(),
      }
    })
  }

  return farmsDataWithPrices
}

const masterChefV2Abi = [
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'poolInfo',
    outputs: [
      { internalType: 'uint256', name: 'accCakePerShare', type: 'uint256' },
      { internalType: 'uint256', name: 'lastRewardBlock', type: 'uint256' },
      { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
      { internalType: 'uint256', name: 'totalBoostedShare', type: 'uint256' },
      { internalType: 'bool', name: 'isRegular', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'poolLength',
    outputs: [{ internalType: 'uint256', name: 'pools', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalRegularAllocPoint',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSpecialAllocPoint',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: '_isRegular', type: 'bool' }],
    name: 'cakePerBlock',
    outputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const

const masterChefFarmCalls = (farm: SerializedFarmConfig, masterChefAddress: string) => {
  const { pid } = farm

  return pid || pid === 0
    ? ({
      abi: masterChefV2Abi,
      address: masterChefAddress as Address,
      functionName: 'poolInfo',
      args: [BigInt(pid)],
    } as const)
    : null
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const fetchMasterChefData = async (
  farms: SerializedFarmConfig[],
  isTestnet: boolean,
  provider: ({ chainId }: { chainId: number }) => PublicClient,
  masterChefAddress: string,
) => {
  try {
    const masterChefCalls = farms.map((farm) => masterChefFarmCalls(farm, masterChefAddress))
    const masterChefAggregatedCalls = masterChefCalls.filter(notEmpty)

    const chainId = isTestnet ? ChainId.BSC_TESTNET : ChainId.BSC
    const masterChefMultiCallResult = await provider({ chainId }).multicall({
      contracts: masterChefAggregatedCalls,
      allowFailure: false,
    })

    let masterChefChunkedResultCounter = 0
    return masterChefCalls.map((masterChefCall) => {
      if (masterChefCall === null) {
        return null
      }
      const data = masterChefMultiCallResult[masterChefChunkedResultCounter]
      masterChefChunkedResultCounter++
      return {
        accCakePerShare: data[0],
        lastRewardBlock: data[1],
        allocPoint: data[2],
        totalBoostedShare: data[3],
        isRegular: data[4],
      }
    })
  } catch (error) {
    console.error('MasterChef Pool info data error', error)
    throw error
  }
}

export const fetchMasterChefV2Data = async ({
  provider,
  isTestnet,
  masterChefAddress,
}: {
  provider: ({ chainId }: { chainId: number }) => PublicClient
  isTestnet: boolean
  masterChefAddress: Address
}) => {
  try {
    const chainId = isTestnet ? ChainId.BSC_TESTNET : ChainId.BSC
    const [poolLength, totalRegularAllocPoint, totalSpecialAllocPoint, cakePerBlock] = await provider({
      chainId,
    }).multicall({
      contracts: [
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'poolLength',
        },
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'totalRegularAllocPoint',
        },
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'totalSpecialAllocPoint',
        },
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'cakePerBlock',
          args: [true],
        },
      ],
      allowFailure: false,
    })

    return {
      poolLength,
      totalRegularAllocPoint,
      totalSpecialAllocPoint,
      cakePerBlock,
    }
  } catch (error) {
    console.error('Get MasterChef data error', error)
    throw error
  }
}

type balanceResponse = bigint

export type ClassicLPData = [
  balanceResponse,
  balanceResponse,
  balanceResponse,
  balanceResponse,
  balanceResponse,
  balanceResponse,
  balanceResponse,
]

type FormatClassicFarmResponse = {
  tokenBalanceLP: BN
  quoteTokenBalanceLP: BN
  lpTokenBalanceMC: BN
  lpTotalSupply: BN
  lockPeriod: BN
  rewardRate: BN,
  endDate: BN
}

const formatClassicFarmResponse = (farmData: ClassicLPData): FormatClassicFarmResponse => {
  const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply, lockPeriod, rewardRate, endDate] = farmData

  return {
    tokenBalanceLP: new BN(tokenBalanceLP.toString()),
    quoteTokenBalanceLP: new BN(quoteTokenBalanceLP.toString()),
    lpTokenBalanceMC: new BN(lpTokenBalanceMC.toString()),
    lpTotalSupply: new BN(lpTotalSupply.toString()),
    lockPeriod: new BN(lockPeriod.toString()),
    rewardRate: new BN(rewardRate.toString()),
    endDate: new BN(endDate.toString()),
  }
}

const getClassicFarmsDynamicData = ({
  lpTokenBalanceMC,
  lpTotalSupply,
  rewardRate,
  quoteTokenBalanceLP,
  tokenBalanceLP,
  token0Decimals,
  token1Decimals,
  lockPeriod,
  endDate,
}: FormatClassicFarmResponse & {
  token0Decimals: number
  token1Decimals: number
  lpTokenStakedAmount?: string
  apy?: string
}) => {
  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = getTokenAmount(tokenBalanceLP, token0Decimals)
  const quoteTokenAmountTotal = getTokenAmount(quoteTokenBalanceLP, token1Decimals)

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio =
    !lpTotalSupply.isZero() && !lpTokenBalanceMC.isZero() ? lpTokenBalanceMC.div(lpTotalSupply) : BIG_ZERO

  // const apyAsBigNumber = rewardRate.multipliedBy(SECONDS_IN_YEAR).shiftedBy(-18)

  // // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMcFixed = quoteTokenAmountTotal.times(lpTokenRatio.isGreaterThanOrEqualTo(1) ? lpTokenRatio : new BigNumber(1).shiftedBy(18).toNumber())

  // // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = quoteTokenAmountMcFixed.times(BIG_TWO).shiftedBy(-18);

  // bee 
  const apyAsBigNumber = rewardRate.multipliedBy(SECONDS_IN_YEAR).shiftedBy(-18).div(lpTotalInQuoteToken).multipliedBy(100)

  return {
    tokenAmountTotal: tokenAmountTotal.toString(),
    quoteTokenAmountTotal: quoteTokenAmountTotal.toString(),
    lpTotalSupply: lpTotalSupply.toString(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toString(),
    tokenPriceVsQuote:
      !quoteTokenAmountTotal.isZero() && !tokenAmountTotal.isZero()
        ? quoteTokenAmountTotal.div(tokenAmountTotal).toString()
        : BIG_ZERO.toString(),
    lpTokenStakedAmount: lpTokenBalanceMC.toString(),
    apy: apyAsBigNumber.toString(),
    lockPeriod: lockPeriod.toString(),
    endDate: endDate?.toString(),
    rewardRate
  }
}